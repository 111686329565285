import { useState, useEffect } from "react";



export default function ModeIcon( {onSelect,onEffect,classString,e} ) {

    const [ isActive, setIsActive ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ isClicked, setIsClicked ] = useState(false);

    // let togSound = new Audio(toggleEffect);
    // let clickSound = new Audio(clickedEffect);

    const onKey = (ev,e) => {
        if (ev.keyCode===13 || ev.keyCode===32) {
            onSelect(e);
        }
    }

    useEffect( ()=>{
        // console.log('classString is now',classString);
        setIsActive( classString.indexOf('active')>-1 ? true : false );
        setIsDisabled( classString.indexOf('disabled')>-1 ? true : false );
        setIsClicked( classString.indexOf('clicked')>-1 ? true : false );
    },[classString,setIsActive,setIsDisabled,setIsClicked]);

    useEffect( ()=>{
        if (isActive) onEffect('toggle');
    },[isActive]);
    useEffect( ()=>{
        if (isDisabled) onEffect('toggle');
    },[isDisabled]);
    useEffect( ()=>{
        if (isClicked) onEffect('click');
    },[isClicked]);

    return (
        <>
        <li className={classString} onClick={ev=>onSelect(e)} onKeyUp={ev=>onKey(ev,e)} tabIndex={classString.indexOf('active')>-1?0:-1}>{e.id}</li>
        
        </>
    )
}